// NewsPage.js
import React from 'react';
import styles from './News.module.css';
const News = () => {
  return (
    <div className={styles.contentPlaceholder}>
      {/* <h2>News</h2>
      <p>November 13-16 - I am attenting the Lisbon Web Summit 2023! Very excited for everything and everyone I will be meeting!</p> */}
    <h2>Nothing to see here - maybe one day :) </h2>
    </div>
  );
};

export default News;
